import { graphql } from 'gatsby'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import tw from 'twin.macro'
import Heading from '../../components/heading'
import Layout from '../../components/layout'
import Profiles from '../../components/profiles'
import { global } from '../../styles/global'
import { flatten } from '../../utils/data'

const RegionalLeadershipPage = ({ data: { localLeadership } }) => {
  const page = useMemo(
    () => flatten(localLeadership || {}, ['localLeadershipHeader']),
    [localLeadership]
  )

  return (
    <Layout pageTags={page.seoMetaTags} noIndex={page.noIndex} headerTransition>
      <section id="local-leadership" css={[global`layout.container`, tw`pt-38`]}>
        <div css={tw`flex justify-center`}>
          <Heading
            content={get(page, 'localLeadershipHeader.titleNode')}
            headingType="h1"
            style={tw`items-center`}
          />
        </div>
        <Profiles
          profiles={get(page, 'localLeadership')}
          nextLabel={get(page, 'nextLabel')}
          closeLabel={get(page, 'closeLabel')}
          style={tw`my-16 lg:mt-24`}
        />
      </section>
    </Layout>
  )
}

RegionalLeadershipPage.propTypes = {
  data: PropTypes.shape({
    localLeadership: PropTypes.object.isRequired,
  }),
}

export default RegionalLeadershipPage

export const query = graphql`
  query LocalLeadershipQuery {
    localLeadership: datoCmsParentLocalLeadership {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      localLeadershipHeader {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
      }
      localLeadership {
        image {
          format
          gatsbyImageData(layout: FULL_WIDTH)
        }
        name
        jobTitle
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      nextLabel
      closeLabel
    }
  }
`
